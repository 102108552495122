import { Box, Typography } from '@material-ui/core';
import { useCreateImageUploader } from 'graphql/hooks/images.hooks';
import { useGetSingleBlogArticle, useGetWebsiteDataForBiz } from 'graphql/hooks/websiteAutomation.hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IArticles } from '../types';
import AddEditSingleBlogArticleForm from './AddEditSingleBlogArticleForm';
const demoData = {
  title: '',
  text: '',
  picture: null,
  imageData: null,
  version: 0
};
const AddEditSingleBlogArticle = () => {
  const { restaurantId } = useActiveRestaurant();

  const { createImageUploadUrl, uploadImage } = useCreateImageUploader();
  const { blogId, blogTitle } = useParams() as any;
  const { data: blogsListData, loading } = useGetSingleBlogArticle(restaurantId, blogId);
  const data = useGetWebsiteDataForBiz(restaurantId, 'VERSION');
  const [initialValues, setInitialValues] = useState<IArticles>(demoData);
  const [isNewArticle, setIsNewArticle] = useState(true);

  useEffect(() => {
    if (blogsListData && blogsListData.getBlog) {
      setInitialValues(blogsListData.getBlog);
      setIsNewArticle(false);
    } else {
      if (data && data.getWebsiteDataForBiz && data.getWebsiteDataForBiz.version)
        setInitialValues({
          title: '',
          text: '',
          picture: null,
          imageData: null,
          version: data.getWebsiteDataForBiz.version
        });
    }
  }, [blogsListData, data]);
  useEffect(() => {
    if (blogTitle) {
      let getStoredBlog: { topic: string; blog: string } = JSON.parse(localStorage.getItem('editLairaBlog') || '{}');
      if (getStoredBlog && getStoredBlog.topic && getStoredBlog.blog) {
        setInitialValues({
          text: getStoredBlog.blog,
          title: getStoredBlog.topic,
          picture: null,
          imageData: null,
          version: data?.getWebsiteDataForBiz?.version
        });
      }
    }
  }, [blogTitle, data]);
  if (loading) {
    return null;
  }
  const getUploadId = async (filename: string, imageData: string) => {
    try {
      const { data: uploadImageResponse } = await createImageUploadUrl({
        variables: {
          input: {
            usage: 'WEBSITE',
            fileName: filename
          }
        }
      });

      if (uploadImageResponse && uploadImageResponse.createImageUploadUrl) {
        const { id, url } = uploadImageResponse.createImageUploadUrl;
        await uploadImage(url, imageData);

        return id;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  return (
    <>
      <Box display={'flex'} justifyContent="space-between" width={'100%'} marginY={2}>
        <Typography variant="h5">{isNewArticle ? 'Write a new Article' : 'Edit your Article'}</Typography>
      </Box>
      <Box display={'flex'} flexDirection="column" flex={1}>
        <Box marginBottom={2}>
          <AddEditSingleBlogArticleForm getUploadId={getUploadId} blogId={blogId} initialValues={initialValues} isNewArticle={isNewArticle} />
        </Box>
      </Box>
    </>
  );
};

export default AddEditSingleBlogArticle;
