import { gql } from 'apollo-boost';

export const askLairaQury = gql`
  query askLaira($input: askLairaInput!) {
    askLaira(input: $input) {
      content
      responseType
      functionMetaDeta
      topic
    }
  }
`;
