import { Box, Tab, Tabs, Typography, withStyles, useTheme, Divider } from '@material-ui/core';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useStore } from 'store';
import MenuCardMSP from './MenuCardMSP';
import StoreCardMSP from './StoreCardMSP';
import { useStyles } from './style';
import TableCardMSP from './TableCardMSP';
import BusinessCardMSP from './BusinessCardMSP';
import WebsiteCardMSP from './WebsiteCardMSP';
import { useGetWebsitePartnerStatus } from 'graphql/hooks/websiteAutomation.hooks';

const Msp = () => {
  const classes = useStyles();
  const {
    state: { restaurant }
  } = useStore();
  const partnerStatus = useGetWebsitePartnerStatus(restaurant || '');

  const [isLokobeePartnerState, setIsLokobeePartnerState] = useState<boolean>(false);
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);
  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  const _setActiveTab = (_event: React.ChangeEvent<{}>, index: number) => {
    setActiveTab(index);
  };

  const [initialValues, setInitialValues] = useState({
    name: '',
    subName: ''
  });
  const { data } = useGetRestaurantById(restaurant, 'PROFILE');

  useEffect(() => {
    if (partnerStatus && partnerStatus.getWebsiteDataForBiz) {
      const { customDomain } = partnerStatus.getWebsiteDataForBiz;
      if (customDomain) {
        setIsLokobeePartnerState(true);
      }
    }
  }, [partnerStatus]);
  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { name, subName } = data.ownerGetRestaurantById;

      setInitialValues({
        name: name || '',
        subName: subName || ''
      });
    }
  }, [data]);
  if (data && data.ownerGetRestaurantById) {
    return (
      <>
        <Box display={'flex'}>
          <Box flexGrow={1} display={'flex'} alignItems="center">
            <Typography variant="h6">Marketing Strategy Plan</Typography>
          </Box>
          <Box flexGrow={1} className={classes.tabsContainer}>
            <StyledTabs value={activeTab} onChange={_setActiveTab}>
              <Tab className={classes.tabs} label={'Store Card'} id="0" />
              <Tab className={classes.tabs} label={'Table Card'} id="1" />
              <Tab className={classes.tabs} label={'Menu Card'} id="2" />
              <Tab className={classes.tabs} label={'Business Card'} id="3" />
              {isLokobeePartnerState && <Tab className={classes.tabs} label={'Social Media Card'} id="4" />}
            </StyledTabs>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box marginTop={5}>
          {activeTab === 0 && <StoreCardMSP initialValues={initialValues} restaurantId={restaurant} />}
          {activeTab === 1 && <TableCardMSP restaurantId={restaurant} />}
          {activeTab === 2 && <MenuCardMSP initialValues={initialValues} restaurantId={restaurant} />}
          {activeTab === 3 && <BusinessCardMSP restaurantId={restaurant} />}
          {activeTab === 4 && <WebsiteCardMSP restaurantId={restaurant} />}
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};
export default Msp;
