import React from 'react';
import Routes from './routes';
import useRestaurant from 'hooks/restaurant/useRestaurant';
import { Box } from '@material-ui/core';
import { useStyles } from './style';
import LairaChat from 'components/LairaChat';
import { useParams } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const isValid = useRestaurant();
  const { restaurantId } = useParams<any>();

  const classes = useStyles();

  if (isValid) {
    return (
      <Box className={classes.gridContainer} paddingX={2} margin="auto">
        <Routes />
        {process.env.REACT_APP_LAIRA_CONFIG === 'true' && <LairaChat bizId={restaurantId} />}
      </Box>
    );
  }

  return null;
};

export default Dashboard;
