import { Articles } from 'generated/types';
import { CREATE_NEW_BLOG, DELETE_BLOG, UPDATE_BLOG } from 'graphql/mutations';
import { webSiteCardMutation } from 'graphql/mutations/webSiteCard.mutation';
import { createWebsiteData, updateWebsiteData } from 'graphql/mutations/websiteData.mutation';
import { GET_SINGLE_BLOG_ARTICLE, GET_WEBSITE_BLOGS } from 'graphql/query';
import { checkWebsiteUrlExits } from 'graphql/query/checkWebsiteUrlExits';
import { getWebsiteCardImageQuery } from 'graphql/query/getWebsiteCardImage.query';
import { getWebsiteData } from 'graphql/query/getWebsiteData.query';
import { getWebsiteDataCustomDomain } from 'graphql/query/getWebsiteDataCustomDomain.query';
import { getWebsiteDataVersion } from 'graphql/query/getWebsiteDataVersion.query';
import { useMutationWithLoader, useQueryWithLoader } from 'hooks/loader';
import { filter } from 'lodash';

export const useUpdateWebsiteDataMutation = (bizId: string | null) => {
  const [updateWebsite, { data, loading, error }] = useMutationWithLoader(updateWebsiteData, {
    update: (cache, data) => {
      if (bizId && data && data.data && data.data.updateWebsite) {
        cache.writeQuery({
          query: getWebsiteData,
          variables: {
            bizId
          },
          data: {
            getWebsiteDataForBiz: { ...data.data.updateWebsite }
          }
        });
      }
    }
  });

  return { updateWebsite, data, loading, error };
};

export const useCreateWebsiteData = (bizId: string | null) => {
  const [createWebsite, { data, loading, error }] = useMutationWithLoader(createWebsiteData);
  return { createWebsite, data, loading, error };
};

export const useGetWebsiteDataForBiz = (bizId: string | null, requestType?: 'VERSION' | 'WEBSITE_DATA') => {
  const requestQuery = requestType === 'VERSION' ? getWebsiteDataVersion : getWebsiteData;
  const { data } = useQueryWithLoader(requestQuery, {
    fetchPolicy: requestType === 'VERSION' ? 'no-cache' : 'cache-and-network',
    variables: {
      bizId
    },
    skip: !bizId
  });

  if (data) {
    return data;
  }
  return null;
};

export const useCheckWebsiteUrl = (url: string) => {
  const { data, refetch, loading } = useQueryWithLoader(checkWebsiteUrlExits, {
    skip: url.trim() === '',
    variables: {
      webisteUrl: url
    }
  });

  return { data, refetch, loading };
};
export const useGetWebsiteBlogs = (bizId: string | null) => {
  const { data } = useQueryWithLoader(GET_WEBSITE_BLOGS, {
    variables: {
      input: { bizId: bizId }
    },
    skip: !bizId
  });

  if (data) {
    return data;
  }
  return null;
};

export const useGetSingleBlogArticle = (bizId: string | null, blogId: string | null) => {
  const { loading, data, error } = useQueryWithLoader(GET_SINGLE_BLOG_ARTICLE, {
    fetchPolicy: 'no-cache',
    skip: !blogId || !bizId,
    variables: {
      input: { bizId, blogId }
    }
  });

  return {
    loading,
    data,
    error
  };
};
export const useUpdateBlogMutation = (bizId: string | null) => {
  const [updateWebsiteBlog, { data, loading, error }] = useMutationWithLoader(UPDATE_BLOG, {
    update: (cache, newData) => {
      if (bizId && newData && newData.data.updateWebsiteBlog) {
        const variables = {
          input: {
            bizId: bizId
          }
        };
        const { getAllBlogsByBizId: oldData }: any = cache.readQuery({
          query: GET_WEBSITE_BLOGS,
          variables
        });
        let blogId = newData.data.updateWebsiteBlog?.id;
        let articles: Articles[] = oldData;
        let newArList = articles.filter((article) => article.id !== blogId);
        newArList.forEach((newAr) => {
          newAr.version = newData.data.updateWebsiteBlog?.version;
        });
        let newArticlesList = [...newArList, newData.data.updateWebsiteBlog];
        cache.writeQuery({
          query: GET_WEBSITE_BLOGS,
          variables: {
            input: { bizId: bizId }
          },

          data: {
            getAllBlogsByBizId: newArticlesList
          }
        });
      }
    }
  });

  return { updateWebsiteBlog, data, loading, error };
};

export const useCreateBlogMutation = (bizId: string | null) => {
  const [createWebsiteBlog, { data, loading, error }] = useMutationWithLoader(CREATE_NEW_BLOG, {
    update: (cache, { data: { createWebsiteBlog: newData } }) => {
      const variables = {
        input: {
          bizId: bizId
        }
      };

      const { getAllBlogsByBizId: oldData }: any = cache.readQuery({
        query: GET_WEBSITE_BLOGS,
        variables
      });

      cache.writeQuery({
        variables,
        query: GET_WEBSITE_BLOGS,
        data: { getAllBlogsByBizId: oldData ? [newData, ...oldData] : [newData] }
      });
    }
  });
  return { createWebsiteBlog, data, loading, error };
};

export const useDeleteBlogMutation = (bizId: string, blogId: string, version: number | null | undefined) => {
  const [deleteWebsiteBlog, { data, error, loading }] = useMutationWithLoader(DELETE_BLOG, {
    variables: {
      input: {
        bizId,
        blogId,
        version
      }
    },
    update: (cache, { data: { deleteWebsiteBlog: deleteResponse } }) => {
      const variables = {
        input: {
          bizId
        }
      };
      if (deleteResponse && deleteResponse.status) {
        const { getAllBlogsByBizId: oldData }: any = cache.readQuery({
          query: GET_WEBSITE_BLOGS,
          variables
        });
        let oldArticles: Articles[] = [...oldData];
        oldArticles.forEach((oldArticle) => {
          if (oldArticle.version) {
            oldArticle.version = deleteResponse?.version;
          }
        });
        cache.writeQuery({
          variables,
          query: GET_WEBSITE_BLOGS,
          data: { getAllBlogsByBizId: filter(oldData, (blogArticle) => blogArticle.id !== blogId) }
        });
      }
    }
  });

  return {
    deleteWebsiteBlog,
    data,
    error,
    loading
  };
};

export const useGetWebsitePartnerStatus = (bizId: string | null) => {
  const { data } = useQueryWithLoader(getWebsiteDataCustomDomain, {
    fetchPolicy: 'cache-and-network',
    variables: {
      bizId
    },
    skip: !bizId
  });

  if (data) {
    return data;
  }
  return null;
};

export const useCreateWebSiteCard = (bizId: string | null) => {
  const [createWebSiteCard, { data, loading, error }] = useMutationWithLoader(webSiteCardMutation);
  return { createWebSiteCard, data, loading, error };
};

export const useGetWebsiteCardImage = (bizId: string | null) => {
  const { loading, data, error } = useQueryWithLoader(getWebsiteCardImageQuery, {
    skip: !bizId,
    variables: {
      input: {
        bizId: bizId
      }
    }
  });

  return {
    loading,
    data,
    error
  };
};
