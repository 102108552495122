import React, { useEffect, useState } from 'react';
import BusinessHours from './BusinessHours';
import { IBizHour } from '../types';
import mockdata from './mockdata.json';
import { useStore } from 'store';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import * as _ from 'lodash';
import { BizHour, ServiceType } from 'generated/types';

interface IProps {
  nextStep: () => void;
}

export default React.forwardRef(({ nextStep }: IProps, ref) => {
  const {
    state: { restaurant }
  } = useStore();

  const [initialValues, setinitialValues] = useState(
    mockdata.map((mockdata: any) => {
      return { ...mockdata, serviceType: 'RESTAURANT_PICK_UP' };
    })
  );
  const { data } = useGetRestaurantById(restaurant, 'BUSINESS_HOURS');

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { bizHours: _bizHours } = data.ownerGetRestaurantById;
      if (_bizHours) {
        const diningBizHours: BizHour[] = _bizHours?.filter((bizHour: IBizHour) => bizHour.serviceType === ServiceType.RestaurantDining);
        if (diningBizHours) {
          setinitialValues(
            _.map(diningBizHours, (hour: IBizHour) => {
              return { serviceName: hour.serviceName, serviceType: hour.serviceType, hours: hour.hours.map((h) => _.omit(h, ['__typename'])) };
            })
          );
        }
      }
    }
  }, [data]);

  if (data && data.ownerGetRestaurantById) {
    return <BusinessHours ref={ref} initialValues={initialValues} nextStep={nextStep} />;
  }

  return null;
});
