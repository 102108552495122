import gql from 'graphql-tag';

export const webSiteCardMutation = gql`
  mutation createWebSiteCard($input: CreateWebSiteCardInput!) {
    createWebSiteCard(input: $input) {
      id
      cardImg {
        url
        id
      }
    }
  }
`;
