import { Box, Grid, Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from '../style';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FirebaseContext } from 'fbase';
import { useContext } from 'react';
import S3Image from 'components/S3Image';
import restaurantEmptyIcon from 'assets/img/restaurant-empty-icon.png';
import { useCreateWebSiteCard, useGetWebsiteCardImage } from 'graphql/hooks/websiteAutomation.hooks';

interface IProps {
  restaurantId: string;
}

const WebsiteCardMSP = ({ restaurantId }: IProps) => {
  const classes = useStyles();

  const { createWebSiteCard, data: cardDataCreated, error: getCardError } = useCreateWebSiteCard(restaurantId || '');
  const { data: cardData, error: createCardError } = useGetWebsiteCardImage(restaurantId || '');
  const { firebase } = useContext(FirebaseContext);

  const [cardUrl, setCardUrl] = useState('');

  const createNewCard = useCallback(() => {
    createWebSiteCard({
      variables: {
        input: {
          bizId: restaurantId
        }
      }
    });
  }, [createWebSiteCard, restaurantId]);

  useEffect(() => {
    if (cardData && cardData.getWebsiteCardImage) {
      if (cardData.getWebsiteCardImage.id && cardData.getWebsiteCardImage.cardImg) {
        const { cardImg, id } = cardData.getWebsiteCardImage;
        const path = `/website_card/${id}`;
        firebase
          ?.getRealtimeDB()
          .ref(path)
          .on('value', (val) => {
            const dbStatusValue = val.val();
            console.log('dbStatusValue1', dbStatusValue);
            if (dbStatusValue) {
              if (dbStatusValue.status === 'done') {
                setCardUrl(cardImg?.url);
                firebase
                  ?.getRealtimeDB()
                  .ref(path)
                  .off('value');
              } else {
                createNewCard();
              }
            } else {
              createNewCard();
            }
          });
        // setCardUrl(cardImg?.url);
      } else {
        createNewCard();
      }
    }
  }, [cardData, createNewCard, firebase]);
  useEffect(() => {
    if (cardDataCreated && cardDataCreated.createWebSiteCard) {
      const { id, cardImg } = cardDataCreated.createWebSiteCard;
      if (id) {
        const path = `/website_card/${id}`;
        firebase
          ?.getRealtimeDB()
          .ref(path)
          .on('value', (val) => {
            const dbStatusValue = val.val();
            console.log('dbStatusValue', dbStatusValue);
            if (dbStatusValue) {
              if (dbStatusValue.status === 'done') {
                setCardUrl(cardImg?.url);
                firebase
                  ?.getRealtimeDB()
                  .ref(path)
                  .off('value');
              }
            }
          });
      }
    }
  }, [cardDataCreated, firebase]);

  return getCardError || createCardError ? (
    <Box width={'100%'} justifyContent="center" alignItems={'center'} display="flex" padding={3}>
      <Typography align="center" variant="body1">
        Something went wrong, please try again later!
      </Typography>
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={6} md={6}>
        {cardUrl && cardUrl !== '' && (
          <Box display="flex" justifyContent="center">
            <a href={cardUrl} download="business-card.png">
              <Button variant="contained" color="primary" disableElevation={true} startIcon={<GetAppIcon />}>
                Download
              </Button>
            </a>
          </Box>
        )}
      </Grid>
      <Grid item xs={6} lg={6} md={6}>
        {cardUrl && cardUrl !== '' ? (
          <Box key={'front_img'}>
            <Box display={'flex'} flexDirection="column" alignItems="center" justifyContent={'center'}>
              <Box marginBottom={2} height={'480px'}>
                <S3Image key={'front_img'} src={cardUrl} className={classes.cardStyle} defaultImage={restaurantEmptyIcon} />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box width={'100%'} justifyContent="center" alignItems={'center'} display="flex" padding={3}>
            <Typography align="center" variant="body1">
              Loading preview...
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default WebsiteCardMSP;
