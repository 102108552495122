import { Dialog } from '@lokobee/lokobee-ui';
import { Button, Typography, Box } from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
interface IProps {
  openImage: boolean;
  setIsOpenImage: React.Dispatch<React.SetStateAction<boolean>>;
  imageToOpen: string;
}
const LairaImageViewer: React.FC<IProps> = ({ openImage, setIsOpenImage, imageToOpen }) => {
  const downloadImg = async (imgUrl: string) => {
    const res = await fetch(imgUrl);
    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = `marketing-image.png`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <Dialog open={openImage} setOpen={setIsOpenImage} dialogProps={{ maxWidth: 'md' }}>
      <Dialog.DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={imageToOpen} alt="Laira marketing Response" style={{ maxWidth: '550px', width: '100%' }} />
          <Box display="flex" alignItems="center" justifyContent="center" marginY={2} width={'100%'}>
            <Button variant="contained" color="primary" style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} onClick={() => downloadImg(imageToOpen)}>
              <GetAppIcon style={{ marginRight: 8 }} />
              <Typography> Download</Typography>
            </Button>
            <Button variant="contained" style={{ display: 'flex', alignItems: 'center' }} onClick={() => setIsOpenImage(false)}>
              <Typography> Close</Typography>
            </Button>
          </Box>
        </div>
      </Dialog.DialogContent>
    </Dialog>
  );
};

export default LairaImageViewer;
