import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../style';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const LairaChatHeader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.chatHeader}>
      <Typography variant="caption" className={classes.chatHeaderTitle}>
        <QuestionAnswerIcon fontSize="small" style={{ marginRight: '5px' }} /> Chat with LAIRA
      </Typography>

      <Typography variant="caption" align="center">
        Ask <strong>LAIRA</strong>, the assistant, to assist you with creating discounts, analyzing data, and any other inquiries you may have.
      </Typography>
    </Box>
  );
};

export default LairaChatHeader;
