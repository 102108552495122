import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useActiveRestaurant } from 'hooks/restaurant';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { useStyles } from './style';
import { useHistory } from 'react-router-dom';
import lairaLogo from 'assets/img/laira_icon_small_profile.svg';
import ClearIcon from '@material-ui/icons/Clear';
import lairaMainIcon from 'assets/img/laira_main_icon.svg';
import LairaChatHeader from './LairaChatHeader';
import SendIcon from '@material-ui/icons/Send';
import Skeleton from '@material-ui/lab/Skeleton';
import LairaImageViewer from './LairaImageViewer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { client } from 'index';
import { AskLairaQuery, ConfirmLairaResponseQuery, LairaResponse, LairaResponseType } from 'generated/types';
import { askLairaQury } from 'graphql/query/Laira/askLaira.query';
import { confirmLairaResponseQuery } from 'graphql/query/Laira/confirmLairaResponse.query';
import { PubSub, Auth } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import EditIcon from '@material-ui/icons/Edit';

PubSub.addPluggable(
  new AWSIoTProvider({
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    aws_pubsub_region: process.env.REACT_APP_AWS_PUBSUB_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT
  })
);

Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
  }
});

interface IProps {
  bizId: string;
}

interface Message {
  text: string;
  sender: 'user' | 'bot';
  messageType?: 'image' | 'text' | 'blog';
  specialData?: any;
  functionMetaDataFromPrevious?: any | undefined;
}
let suggestionQuestions = ['Generate Marketing Image', "Today's Revenue", 'List All Promos', 'Generate a Blog'];

const LairaChat: React.FC<IProps> = ({ bizId }) => {
  const { restaurantId } = useActiveRestaurant();

  const history = useHistory();
  const [openImage, setIsOpenImage] = useState(false);
  const classes = useStyles();
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [imageToOpen, setImageToOpen] = useState('');
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const askAI = useCallback(
    async (query: string) => {
      const { data: resultData } = await client.query<AskLairaQuery>({
        query: askLairaQury,
        variables: {
          input: {
            bizId: restaurantId,
            query: query
          }
        },
        fetchPolicy: 'network-only'
      });
      let response: LairaResponse | undefined | null = resultData.askLaira;
      return response;
    },
    [restaurantId]
  );

  const handleBlogSubscription = useCallback(async (response: LairaResponse | null | undefined) => {
    if (response && response.topic) {
      const res: any = await new Promise((resolve, reject) => {
        const sub = PubSub.subscribe(`${response.topic}`).subscribe({
          next: (data: any) => {
            // console.log(data);
            if (data.value && data.value.blog) {
              const blog = data.value.blog;
              const { content, topic } = blog;
              sub.unsubscribe();
              resolve({
                topic,
                blog: content
              });
            }
          },
          error: (error: unknown) => console.error(error),
          complete: () => console.log('--')
        });
      });
      if (res.topic && res.blog) {
        return { content: `Topic : ${res.topic} \n\n Blog :${res.blog}`, responseType: 'ANSWER', specialData: res };
      } else {
        return {
          content: 'Could not generate blog due to some technical error.Please email support@lokobee.com for further assistance.',
          responseType: 'ANSWER'
        };
      }
    } else {
      return {
        content: 'Could not generate blog due to some technical error.Please email support@lokobee.com for further assistance.',
        responseType: 'ANSWER'
      };
    }
  }, []);
  const openBlogEdit = (specialData: any) => {
    if (specialData && specialData?.topic && specialData?.blog) {
      localStorage.setItem(
        'editLairaBlog',
        JSON.stringify({
          topic: specialData?.topic,
          blog: specialData?.blog
        })
      );
      history.push(`/home/${restaurantId}/blogs/add-new-article/${specialData?.topic}`);
    }
  };
  const handleAskAI = useCallback(
    async (askedMessage: Message) => {
      let tempMessages: Message[] = [...messages];
      tempMessages.push(askedMessage);
      setMessages(tempMessages);
      setIsLoading(true);
      const response = await askAI(askedMessage.text);
      if (response) {
        if (response.responseType === LairaResponseType.Subscribe) {
          const blogResponse = await handleBlogSubscription(response);
          let botNewMessage: Message = {
            sender: 'bot',
            functionMetaDataFromPrevious: null,
            text: blogResponse.content,
            messageType: 'blog',
            specialData: blogResponse?.specialData
          };
          tempMessages.push(botNewMessage);
        } else if (response.responseType === LairaResponseType.Image) {
          let botNewMessage: Message = {
            sender: 'bot',
            functionMetaDataFromPrevious: response.functionMetaDeta,
            text: response.content,
            messageType: 'image'
          };
          tempMessages.push(botNewMessage);
        } else {
          let botNewMessage: Message = {
            sender: 'bot',
            functionMetaDataFromPrevious: response.functionMetaDeta,
            text: response.content,
            messageType: 'text'
          };
          tempMessages.push(botNewMessage);
        }
        setMessages(tempMessages);
        setIsLoading(false);
      } else {
        let botNewMessage: Message = {
          sender: 'bot',
          functionMetaDataFromPrevious: null,
          text: 'Technical error. Please email support@lokobee.com for further assistance.',
          messageType: 'text'
        };
        tempMessages.push(botNewMessage);
        setMessages(tempMessages);
        setIsLoading(false);
      }
    },
    [askAI, handleBlogSubscription, messages]
  );

  const confirmInput = useCallback(
    async (input: string, functionMetaDeta: string) => {
      if (input.toLowerCase() === 'n' || input.toLowerCase() === 'no' || input.toLowerCase() === 'nope') {
        return {
          content: 'Alright!',
          responseType: 'ANSWER'
        };
      } else if (
        input.toLowerCase() === 'y' ||
        input.toLowerCase() === 'yes' ||
        input.toLowerCase() === 'yeah' ||
        input.toLowerCase() === 'yep' ||
        input.toLowerCase() === 'yo' ||
        input.toLowerCase() === 'aye' ||
        input.toLowerCase() === 'sure'
      ) {
        const { data: resultData } = await client.query<ConfirmLairaResponseQuery>({
          query: confirmLairaResponseQuery,
          variables: {
            input: {
              functionMetaDeta: functionMetaDeta
            }
          },
          fetchPolicy: 'network-only'
        });
        let response: LairaResponse | undefined | null = resultData.confirmLairaResponse;
        return response;
      } else {
        const userNewMessage: Message = {
          text: input,
          sender: 'user'
        };
        handleAskAI(userNewMessage);
        return null;
      }
    },
    [handleAskAI]
  );

  const handleAskSuggestedQuestion = (que: string) => {
    const userNewMessage: Message = {
      text: que,
      sender: 'user'
    };
    handleAskAI(userNewMessage);
  };

  const handleSendMessage = async (e: React.MouseEventHandler<HTMLButtonElement> | React.KeyboardEventHandler<HTMLDivElement> | any) => {
    e.preventDefault();
    if (inputText.trim() === '') return;
    let tempMessages: Message[] = [...messages];
    if (messages.length > 0 && messages[messages.length - 1].functionMetaDataFromPrevious) {
      const userNewMessage: Message = {
        sender: 'user',
        text: inputText
      };
      setInputText('');
      tempMessages.push(userNewMessage);
      setMessages(tempMessages);
      setIsLoading(true);
      const response = await confirmInput(inputText, messages[messages.length - 1].functionMetaDataFromPrevious);
      if (response) {
        let botNewMessage: Message = {
          sender: 'bot',
          text: response?.content || ''
        };
        tempMessages.push(botNewMessage);
        setIsLoading(false);
        setMessages(tempMessages);
      }
    } else {
      const userNewMessage: Message = {
        text: inputText,
        sender: 'user'
      };
      setInputText('');
      handleAskAI(userNewMessage);
    }
  };

  useEffect(() => {
    // Auto-scroll to the bottom when a new message arrives
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [isLoading]);
  const toggleChatBox = () => {
    let tempShowChat = showChat;
    setShowChat(!tempShowChat);
  };
  return (
    <>
      <Box>
        <Box className={classes.root} component="form" style={{ height: showChat ? 'auto' : '0px', overflow: 'hidden' }}>
          <LairaChatHeader />
          <Box style={{ width: '100%' }}>
            <Paper className={classes.chatContainer} ref={chatContainerRef}>
              {messages.map((message, index) => (
                <Box key={message.text + index} width="100%">
                  <Box
                    className={`${classes.roleWrapper} ${message.sender === 'user' ? classes.senderMessageWrapper : classes.botMessageWrapper}`}
                    style={{ flexDirection: message.sender === 'user' ? 'row-reverse' : 'row' }}>
                    <Box display={'flex'}>
                      {message.sender === 'bot' && <img src={lairaLogo} className={classes.profileIcon} width="30px" height="30px" alt="Laira" />}
                      <Box display="flex" flexDirection={'column'} marginLeft={1} alignItems={message.sender === 'bot' ? 'flex-start' : 'flex-end'}>
                        <Typography variant="caption" style={{ fontSize: '10px' }}>
                          {' '}
                          {message.sender === 'bot' ? 'Laira' : 'You'}
                        </Typography>
                        {message.messageType === 'image' && message.text ? (
                          <Box className={`${message.sender === 'user' ? classes.senderMessageContent : classes.botMessageContent}`} style={{ width: 'auto' }}>
                            <img src={message.text} alt="marketing" width={'200px'} style={{ marginBottom: '4px' }} />
                            <Button
                              style={{ margin: '0 auto', display: 'flex', alignItems: 'center' }}
                              onClick={() => {
                                setImageToOpen(message.text);
                                setIsOpenImage(true);
                              }}>
                              <VisibilityIcon style={{ marginRight: 8 }} />
                              Open
                            </Button>
                          </Box>
                        ) : (
                          <>
                            <Typography variant="caption" className={`${classes.message} ${message.sender === 'user' ? classes.senderMessageContent : classes.botMessageContent}`}>
                              {message.text}

                              {message.messageType === 'blog' && message?.specialData && (
                                <>
                                  <br />
                                  <br />
                                  <Button variant="contained" style={{ fontSize: 12 }} color="secondary" size="small" onClick={() => openBlogEdit(message?.specialData)}>
                                    <EditIcon fontSize="small" style={{ marginRight: 5 }} /> Edit this Blog
                                  </Button>
                                </>
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {index === messages.length - 1 && isLoading && (
                    <Box className={`${classes.roleWrapper} ${classes.botMessageWrapper}`} style={{ flexDirection: 'row' }} width={'100%'} marginBottom={1}>
                      <Box display={'flex'} width="100%">
                        <Skeleton variant="circle" style={{ width: '30px', height: '25px' }}></Skeleton>
                        <Box
                          display="flex"
                          flexDirection={'column'}
                          marginLeft={1}
                          alignItems={'flex-start'}
                          bgcolor={'white'}
                          height="45px"
                          boxShadow={'rgb(237 232 232) 0px 1px 3px 0px'}
                          borderRadius="10px"
                          width="100%"
                          padding={1}>
                          <Skeleton height={'16px'} width={'100%'} />
                          <Skeleton height={'16px'} width={'100%'} />
                          <Skeleton height={'16px'} width={'60%'} />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Paper>
            {messages.length < 5 && suggestionQuestions.length > 0 && (
              <Box display={'flex'} flexDirection="column" width={'100%'} alignItems={'center'} bgcolor="white" justifyContent="center">
                <Typography variant="caption" align="center">
                  Suggestions
                </Typography>
                <Box display={'flex'} justifyContent="center" flexWrap={'wrap'}>
                  {suggestionQuestions.map((suggestion: string, index: number) => {
                    return (
                      <Typography
                        key={suggestion + index}
                        variant="caption"
                        align="center"
                        onClick={() => {
                          if (!isLoading) {
                            handleAskSuggestedQuestion(suggestion);
                            const index = suggestionQuestions.indexOf(suggestion);
                            if (index > -1) {
                              suggestionQuestions.splice(index, 1);
                            }
                          }
                        }}
                        className={classes.suggestionQuestions}>
                        {suggestion}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            )}

            <Box width={'100%'} display="flex">
              <TextField
                placeholder="your message..."
                className={classes.lairaChatInput}
                variant="outlined"
                fullWidth
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                inputProps={{ className: classes.lairaChatInput }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage(e);
                  }
                }}
              />
              <Button variant="contained" disabled={isLoading} color="primary" size="small" onClick={handleSendMessage}>
                <SendIcon />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.lairaFloating} onClick={toggleChatBox}>
          {showChat ? <ClearIcon fontSize="default" /> : <img src={lairaMainIcon} alt="laira" />}
        </Box>
      </Box>
      <LairaImageViewer openImage={openImage} imageToOpen={imageToOpen} setIsOpenImage={setIsOpenImage} />
    </>
  );
};

export default LairaChat;
