import { gql } from 'apollo-boost';

export const confirmLairaResponseQuery = gql`
  query confirmLairaResponse($input: confirmLairaResponseInput!) {
    confirmLairaResponse(input: $input) {
      content
      responseType
      functionMetaDeta
      topic
    }
  }
`;
