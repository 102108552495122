import { gql } from 'apollo-boost';
export const getWebsiteCardImageQuery = gql`
  query getWebsiteCardImage($input: GetWebSiteCardInput!) {
    getWebsiteCardImage(input: $input) {
      id
      cardImg {
        id
        usage
        url
      }
    }
  }
`;
