import { makeStyles } from '@material-ui/core';
import bgDots from 'assets/img/BG_Dots.svg';
export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 85,
    right: 20,
    width: '300px',
    paddingTop: 0,
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 100,
    boxShadow: '0px 0px 20px #00000029',
    borderRadius: '10px'
  },
  chatContainer: {
    height: '300px', // Adjust the height as needed
    overflowY: 'auto',
    padding: theme.spacing(1, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingRight: theme.spacing(1.5)
  },
  message: {
    marginBottom: theme.spacing(1),
    wordBreak: 'break-word'
  },
  senderMessageWrapper: {
    alignSelf: 'flex-end'
  },
  botMessageWrapper: {
    alignSelf: 'flex-start'
  },
  senderMessageContent: {
    background: '#f0f0f0',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2)
  },
  botMessageContent: {
    background: theme.palette.primary.light,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    whiteSpace: 'pre-line'
  },
  roleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chatHeader: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: theme.spacing(1, 1),
    alignItems: 'center',
    borderRadiusTopLeft: '10px',
    borderRadiusTopRight: '10px',
    backgroundImage: `url(${bgDots})`
  },
  chatHeaderTitle: {
    display: 'flex',
    background: '#FFDB87 0% 0% no-repeat padding-box',
    padding: theme.spacing(0.5, 1),
    borderRadius: '4px',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  lairaFloating: {
    borderRadius: '100%',
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    position: 'fixed',
    bottom: 15,
    right: 30,
    paddingTop: 0,
    cursor: 'pointer',
    zIndex: 100,
    boxShadow: '0px 0px 20px #00000029',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'scale(1.15, 1.15)'
    }
  },
  profileIcon: {
    borderRadius: '100%',
    background: theme.palette.primary.main
  },
  lairaChatInput: {
    background: '#fff',
    fontSize: 'smaller'
  },
  suggestionQuestions: {
    borderRadius: '4px',
    fontSize: '10px',
    padding: theme.spacing(0.5),
    border: '0.6000000238418579px solid #DCDCDC',
    margin: theme.spacing(0.5, 1),
    cursor: 'pointer'
  }
}));
