import React, { useEffect } from 'react';
import { useStyles } from './style';
import { Grid, Box, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BusinessHoursTable from '../BusinessHours/BusinessHoursTable';
import S3Image from 'components/S3Image';
import { IBizHour } from '../types';
import * as _ from 'lodash';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';
import { useStore } from 'store';
import { formatPhoneNumber } from 'util/number';
import { ServiceType } from 'generated/types';

interface IProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;

  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const RestaurantInfoPreview = ({ setActiveStep, setEditing }: IProps) => {
  const classes = useStyles();

  const editData = (step: number) => {
    setEditing(true);
    setActiveStep(step);
  };

  const {
    state: { restaurant }
  } = useStore();

  const { data } = useGetRestaurantById(restaurant, 'ALL');

  useEffect(() => {
    if (data && data.ownerGetRestaurantById) {
      const { name, bizHours, tables, images } = data.ownerGetRestaurantById;
      if (!name && !images && !bizHours && !tables) {
        setActiveStep(0);
      }
    }
  }, [data, setActiveStep]);

  if (!data) {
    return null;
  }
  if (data && data.ownerGetRestaurantById) {
    const { name, description, taxRate, phone, address, logo, bizHours, tables, images, i18n } = data.ownerGetRestaurantById;

    if (name || images || bizHours || tables) {
      const diningBizHours = bizHours?.filter((bizHour: IBizHour) => bizHour.serviceType === ServiceType.RestaurantDining);

      const _taxRate = taxRate ? (taxRate.intValue * Math.pow(10, taxRate.shift + 2)).toFixed(2) + ' %' : '';

      const { timezone: timeZone } = i18n;

      const _phone = formatPhoneNumber(phone);

      return (
        <Grid container={true} className={classes.previewContainer}>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Profile</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(0)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <Grid container={true}>
                {logo && (
                  <Grid item={true} xs={12} sm={6} md={4}>
                    <S3Image src={logo.url} />
                  </Grid>
                )}
                <Grid item={true} xs={12} sm={6} md={8}>
                  <Box className={classes.profileDetailsContainer}>
                    <Box padding={1}>
                      <Typography variant="h6">{name}</Typography>
                      {description && <Typography variant="body2">{description}</Typography>}
                    </Box>
                    <Box className={classes.profileDetails}>
                      <Box padding={1}>
                        <Typography variant="h6">Tax</Typography>
                        <Typography variant="body2">{_taxRate}</Typography>
                      </Box>
                      <Box padding={1}>
                        <Typography variant="h6">Phone</Typography>
                        <Typography variant="body2">{_phone ? _phone : phone}</Typography>
                      </Box>
                      <Box padding={1}>
                        <Typography variant="h6">Address</Typography>
                        <Typography variant="body2">{address.text}</Typography>
                      </Box>
                      <Box padding={1}>
                        <Typography variant="h6">Time Zone</Typography>
                        <Typography variant="body2">{timeZone}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Business Hours</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(1)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container={true}>
                {!diningBizHours ? (
                  <Box paddingY={1}>
                    <Typography variant="body1"> Business Hours Not Added</Typography>
                  </Box>
                ) : (
                  <>
                    {_.map(diningBizHours, (hour: IBizHour, index: number) => {
                      return (
                        <Grid item={true} xs={12} sm={6} md={4} key={index} className={classes.serviceTable}>
                          <BusinessHoursTable service={hour} />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Table Setup</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(2)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Table No.</TableCell>
                    <TableCell align="center">Seats</TableCell>
                  </TableRow>
                </TableHead>

                {!tables ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Typography variant="body1"> No Tables Added</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {_.map(tables, (tb: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">{tb.tableName}</TableCell>
                          <TableCell align="center">{tb.seats}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded={true} className={classes.expansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelHeader}>
              <Typography variant="h6">Gallery</Typography>
              <Button variant="contained" color="primary" size="small" className={classes.editBtn} onClick={() => editData(3)}>
                Edit
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container={true} spacing={2}>
                {!images ? (
                  <Box paddingY={1}>
                    <Typography variant="body1"> Gallery images not added.</Typography>
                  </Box>
                ) : (
                  <>
                    {_.map(images, (img: any, index) => {
                      const { url } = img.md || { url: '' };
                      return (
                        <Grid item={true} xs={12} sm={6} md={4} key={index}>
                          <Box height="200px" width="100%">
                            <S3Image src={url} />
                          </Box>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      );
    }
  }

  return null;
};

export default RestaurantInfoPreview;
